import firebase from "firebase/compat/app";
import fireanalytics from "firebase/compat/analytics";
import firebasestore from "firebase/compat/storage";
import firebasefunctions from "firebase/compat/functions";

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID
};
var calledGeo = false;

function canAlwaysUse(cty) {
  let alwaysCountries = [
    "US",
    "CA",
    "AU",
    "NZ",
    "MX",
    "ZA",
    "AR",
    "BR",
    "IN",
    "CN"
  ];
  let v = alwaysCountries.includes(cty);
  return v;
}
const firebaseApp = firebase.initializeApp(firebaseConfig);
if (localStorage["cookie:accepted"]) {
  localStorage["ga-on"] = true;
  firebase.analytics();
} else {
  // Turn on the basic analytics for certain countries.
  if (!calledGeo) {
    firebase
      .functions()
      .httpsCallable("getGeoInfo")()
      .then(res => {
        if (canAlwaysUse(res.data.country)) {
          firebase.analytics();
          localStorage["ga-on"] = true;
        }
      });
    calledGeo = true;
  }
}
// export firestore database
export default firebaseApp;
