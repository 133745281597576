<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img class="title-image" src="/images/title.png" />
        </router-link>
        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          @click="toggleBurger()"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu">
        <div class="navbar-start">
          <router-link
            class="navbar-item"
            :to="{ name: 'ListCategory', params: { category: 'tasting' } }"
            @click.native="visit('tasting')"
          >
            Virtual Tastings
          </router-link>
          <router-link
            class="navbar-item"
            :to="{ name: 'ListCategory', params: { category: 'webinar' } }"
            @click.native="visit('webinar')"
          >
            Webinars
          </router-link>
          <router-link
            class="navbar-item"
            :to="{ name: 'ListCategory', params: { category: 'social' } }"
            @click.native="visit('social')"
          >
            Social Events
          </router-link>
          <router-link
            class="navbar-item"
            :to="{ name: 'ListCategory', params: { category: 'IG Live' } }"
            @click.native="visit('ig_list')"
          >
            IG Live
          </router-link>
          <div class="navbar-item field">
            <p class="control has-icons-right">
              <input
                class="input"
                type="search"
                placeholder="Search..."
                v-model="searchTerm"
                @keyup.enter="doSearch"
              />
              <span class="icon is-small is-right">
                <i class="fas fa-search" />
              </span>
            </p>
            <label class="checkbox">
              <span class="ml-3 mr-1">Search videos</span>
              <input type="checkbox" v-model="searchVideos" />
            </label>
          </div>
        </div>
        <div class="navbar-end">
          <div
            class="navbar-item has-dropdown is-hoverable"
            @click="toggleMenu"
          >
            <a class="navbar-link" v-if="user.loggedIn()">My Account</a>
            <div class="navbar-dropdown">
              <router-link :to="{ name: 'ViewProfile' }" class="navbar-item">
                My Profile
              </router-link>
              <router-link :to="{ name: 'ViewBookmarks' }" class="navbar-item">
                My Bookmarks
              </router-link>
              <router-link :to="{ name: 'MyEvents' }" class="navbar-item">
                My Events
              </router-link>
              <router-link :to="{ name: 'AddPastEvent' }" class="navbar-item">
                Add a Past Event
              </router-link>
            </div>
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <a
                class="button is-primary"
                @click="doLogin"
                v-if="!user.loggedIn() && !pendingLogin()"
              >
                Login
              </a>
              <a
                class="button is-primary"
                @click="doLogout"
                v-if="user.loggedIn()"
              >
                Log Out
              </a>
              <button
                class="button is-primary is-loading"
                disabled
                v-if="!user.loggedIn() && pendingLogin()"
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Select your Authentication Provider</p>
          <button
            class="delete"
            aria-label="close"
            @click="showModal = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <Login @loggedin="loggedIn"></Login>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import analytics from "@/models/analytics";
import { EventBus } from "@/models/event-bus";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import Login from "@/components/display/Login";
import vweUser from "@/models/user";

export default {
  name: "NavBar",
  components: { Login },
  data() {
    return {
      showModal: false,
      user: vweUser,
      authUI: null,
      searchTerm: null,
      searchVideos: false
    };
  },
  methods: {
    toggleBurger() {
      document.querySelector(".navbar-menu").classList.toggle("is-active");
    },
    doLogin() {
      this.showModal = true;
    },
    loggedIn() {
      this.showModal = false;
    },
    doLogout() {
      this.$store.dispatch("logout");
      vweUser.logOut();
    },
    visit(eventType) {
      analytics.logEvent(`view_cat_${eventType}`);
    },
    pendingLogin() {
      return this.authUI.isPendingRedirect();
    },
    doSearch() {
      if (!this.searchTerm) {
        return;
      }
      if (this.$route.name != "SearchEvents") {
        this.$router.push({
          name: "SearchEvents",
          params: {
            query: this.searchTerm,
            videos: this.searchVideos
          }
        });
        return;
      }
      EventBus.emit("new-search", {
        query: this.searchTerm,
        videos: this.searchVideos
      });
    },
    toggleMenu(e) {
      let menu = e.currentTarget.querySelector(".navbar-dropdown");
      if (e.target.parentElement.classList.contains("navbar-dropdown"))
        menu.style.display = "none";
      setTimeout(() => {
        menu.style.display = "";
        e.target.blur();
      }, 200);
    }
  },
  created() {
    this.authUI = firebaseui.auth.AuthUI.getInstance();
    if (!this.authUI) {
      this.authUI = new firebaseui.auth.AuthUI(firebase.auth());
    }
  }
};
</script>

<style scoped>
.title-image {
  max-height: 2.6em;
}
#user-image {
  padding-right: 0;
}

#user-image {
  display: inline-flex;
}
#user-image img {
  max-height: 35px;
}
</style>
