<template>
  <div class="entry">
    <div class="grouping">
      <InterestTracker
        class="tracker"
        :event="event"
        v-if="itActive()"
        :disabled="now() > event.date"
      ></InterestTracker>
      <div>
        <p class="title is-5">
          <router-link
            class="event-link"
            :to="{ name: 'ViewEvent', params: { id: event.id } }"
            >{{ event.name }} on {{ dateStr }} at {{ timeStr }}
          </router-link>
        </p>
        <div class="hosted-by">Hosted by {{ event.host.name }}</div>
        <div class="video" v-if="event.videoUrl">
          <a
            :href="event.videoUrl"
            class="has-text-success-dark video-link"
            target="_blank"
            @click="onVisitVideo"
          >
            <span class="icon"><i class="fas fa-video"></i></span>
            {{ event.videoHasFee ? "Purchase" : "View" }} video for this event
          </a>
        </div>
        <div class="tags event-tags">
          <span
            v-for="(category, index) in event.categories"
            :key="index"
            class="tag"
            :class="formatTag(category)"
          >
            {{ category }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import InterestTracker from "./InterestTracker";
import flags from "../../models/flags";
import vweUser from "../../models/user";
import analytics from "../../models/analytics";
import firebase from "firebase/compat/app";

export default {
  name: "EventListItem",
  props: ["event"],
  components: { InterestTracker },
  data() {
    return {};
  },
  methods: {
    formatTag(tag) {
      if (tag == "free") {
        return ["is-success"];
      }
      if (tag == "must register") {
        return ["is-danger", "is-light"];
      }
      return ["is-light", "is-info"];
    },
    itActive() {
      return flags.itActive;
    },
    now() {
      return moment()
        .utc()
        .format();
    },
    // When the user visits the video, we want to record that information.
    onVisitVideo() {
      if (vweUser.isAdmin()) {
        return;
      }
      // conditionally log the event.
      analytics.logEvent("visit_event_video", {
        tags: this.event.categories,
        hour: moment(this.event.date).format("HH")
      });

      let viewDetailEvent = firebase.functions().httpsCallable("onVisitVideo");
      viewDetailEvent({
        id: vweUser.getInterestPersona(),
        eventId: this.event.id
      }).catch(err => {
        console.error("Error calling function ", err);
      });
    }
  },
  computed: {
    dateStr() {
      return moment(this.event.date).format("ll");
    },
    timeStr() {
      return moment(this.event.date).format("LT");
    }
  }
};
</script>

<style scoped>
.entry {
  margin: 10px 35px;
  padding-bottom: 8px;
}
.grouping {
  display: flex;
  flex-flow: row nowrap;
}
.event-link {
  color: black;
}
a:hover.event-link {
  color: #213a87;
}
.event-tags {
  margin-top: 0.75rem;
}
.video {
  font-size: 1.1rem;
  margin-top: 0.2rem;
}
.video-link {
  background-color: rgba(240, 237, 2, 0.3);
}
.hosted-by {
  font-size: 1.1rem;
  margin-top: -8px;
}
</style>
