<template>
  <div class="login-buttons">
    <section id="firebaseui-auth"></section>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import vweUser from "@/models/user";

export default {
  name: "Login",
  data() {
    return {
      user: null
    };
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    var login = this;
    var uiConfig = {
      signInSuccessUrl: "#" + this.$route.fullPath,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      signInFlow: 'popup',
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          login.$emit("loggedin");
          ui.reset();
          ui.start("#firebaseui-auth", uiConfig);
          return false;
        }
      }
    };
    ui.start("#firebaseui-auth", uiConfig);
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.user = user;
        vweUser.setUser(user, theUser => {
          this.$store.dispatch("setUser", theUser);
        });
      }
    });
  }
};
</script>

<style scoped></style>
