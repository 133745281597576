// Methods used to send analytic events to Google when appropriate.
// It's important to take into account whether or not the user has agreed to use cookies. If they
// haven't then we should not send that information to Google.

import firebase from "firebase/compat/app";

let analytics = {
  logEvent(event, params) {
    if (localStorage["ga-on"]) {
      let ga = firebase.analytics();
      ga.logEvent(event, params);
    }
  },
  // We want to be able to log an event if/when someone views a screen rather than
  // just the master view being mounted.
  screenView() {
    if (localStorage["ga-on"]) {
      let ga = firebase.analytics();
      ga.setCurrentScreen(window.location.pathname);
      ga.logEvent("screen_view");
    }
  }
};

export default analytics;
