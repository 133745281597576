import { render, staticRenderFns } from "./AddEvent.vue?vue&type=template&id=0288f57c&scoped=true&"
import script from "./AddEvent.vue?vue&type=script&lang=js&"
export * from "./AddEvent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0288f57c",
  null
  
)

export default component.exports