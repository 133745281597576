<template>
  <div
    class="card"
    :class="{ grayed: isPast() && !videoApproval && !isStarted() }"
  >
    <span class="push-right">
      <span
        :class="{
          'has-text-primary-dark': !editBright,
          'has-text-primary-darker': editBright
        }"
        v-if="showAdmin()"
        @mouseover="editBright = true"
        @mouseleave="editBright = false"
      >
        <span class="icon edit-icon" @click="goEdit">
          <i class="fas fa-lg fa-pen-square"></i>
        </span>
      </span>
      <a class="delete" v-if="showAdmin()" @click="showConfirmation = true"></a>
    </span>
    <div class="card-content">
      <span class="past-text" v-if="isStarted()">
        This event may have started.
      </span>
      <span class="past-text" v-else-if="isPastToday()">
        This event has started or has already passed.
      </span>
      <span class="past-text" v-else-if="isPast() && !videoApproval">
        This event has already passed.
      </span>
      <span class="past-text" v-else-if="videoApproval && showAdmin()">
        This is a past event with a video to be added.
      </span>
      <div class="event-title">
        <a
          class="event-link"
          :href="mainLink"
          target="_blank"
          @click="gotoEvent(eventName)"
          >{{ event.name }}</a
        >
      </div>
      <div class="sharing-links">
        <ShareNetwork
          network="facebook"
          :url="'https://share.virtualwineevents.com/event/' + event.id"
          :title="event.name"
          :style="{ backgroundColor: '#4267b2' }"
          @click.native="shareEvent"
        >
          <i class="fab fah fa-facebook-f"></i>
          <span> Share on Facebook </span>
        </ShareNetwork>
        <ShareNetwork
          network="twitter"
          :url="'https://share.virtualwineevents.com/event/' + event.id"
          :media="
            event.eventImage
              ? eventImage
              : 'https://virtualwineevents.com/profile-sharing.png'
          "
          :title="event.name + ' - from @VirtualWineEvts'"
          hashtags="#virtualwineevents"
          twitter-user="virtualwineevts"
          :style="{ backgroundColor: '#38A1F3' }"
          @click.native="shareEvent"
        >
          <i class="fab fah fa-twitter"></i>
          <span> Share on Twitter </span>
        </ShareNetwork>
      </div>
      <div class="video-info ml-4 my-2" v-if="event.videoUrl">
        <a
          :href="event.videoUrl"
          class="video-link"
          target="_blank"
          @click="gotoEvent(eventName)"
        >
          <span class=" larger-text">
            <span class="icon has-text-success">
              <i class="fas fa-video"></i
            ></span>
            {{ event.videoHasFee ? "Purchase" : "View" }} video for this event.
          </span>
        </a>
      </div>
      <div class="columns">
        <div class="column">
          <div class="event-cost" :class="{ 'event-free': event.cost === '0' }">
            <p>
              {{
                event.cost === "0" || event.cost === "$" ? "Free" : event.cost
              }}
            </p>
          </div>
          <div class="event-time">
            <p>{{ formatEventDate(event.date) }}</p>
          </div>
          <div class="media">
            <div
              class="media-left"
              v-if="false && event.host.hasOwnProperty('user_hash')"
            >
              <figure class="image is-48x48">
                <img
                  :src="
                    'https://www.gravatar.com/avatar/' + event.host.user_hash
                  "
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="media-content" v-if="event.host.hasOwnProperty('name')">
              <a :href="event.link" v-if="event.videoUrl">
                <p class="title is-5">Hosted by: {{ event.host.name }}</p>
              </a>
              <p class="title is-5" v-else>Hosted by: {{ event.host.name }}</p>
              <div class="subtitle is-6">
                <SocialLink
                  network="twitter"
                  :handle="event.host.contact_info.twitter"
                  :event-id="event.id"
                ></SocialLink>
                <SocialLink
                  network="instagram"
                  :handle="event.host.contact_info.instagram"
                  :event-id="event.id"
                ></SocialLink>
              </div>
            </div>
          </div>
        </div>
        <div class="column" v-if="event.eventImage" @click="showImage = true">
          <figure class="image image-spacer push-image">
            <img class="event-image" :src="eventImage" />
          </figure>
        </div>
      </div>
      <div class="content">
        <div>
          <span class="event-desc"> {{ truncate(event.description) }} </span>
          <router-link
            v-if="event.description.length > maxStringLen() && !no_truncate"
            :to="{ name: 'ViewEvent', params: { id: event.id } }"
          >
            (more...)
          </router-link>
        </div>
      </div>
      <div class="card-footer event-card-footer">
        <div class="tags event-tags card-footer-item">
          <EventTag
            v-for="(category, index) in event.categories"
            :key="index"
            :tag="category"
            :url="mainLink"
            @event-followed="gotoEvent(eventName)"
          />
        </div>
        <div class="card-footer-item is-cal-links" v-if="!isPast()">
          <AddToCalendar :event="event" @added="addToCalendar"></AddToCalendar>
        </div>
      </div>
    </div>
    <ImagePopup
      :src="event.eventImage"
      :show-image="showImage"
      @close="showImage = false"
    ></ImagePopup>
    <Confirmation
      :shown="showConfirmation"
      title="Are you sure?"
      message="Are you sure you want to delete this event?"
      @yes="deleteMe"
      @no="showConfirmation = false"
    >
    </Confirmation>
  </div>
</template>

<script>
import moment from "moment-timezone";
import firebase from "firebase/compat/app";

import analytics from "../../models/analytics";
import db from "../../firebase/firestore";
import errors from "../../models/errors";
import vweUser from "../../models/user";

import EventTag from "../widgets/EventTag";
import AddToCalendar from "../widgets/AddToCalendar";
import ImagePopup from "../widgets/ImagePopup";
import SocialLink from "../widgets/SocialLink";
import Confirmation from "../widgets/Confirmation";

export default {
  name: "EventCard",
  props: {
    event: Object,
    date_format: {
      type: String,
      default: "LT"
    },
    no_truncate: {
      type: Boolean,
      default: false
    },
    "video-approval": {
      type: Boolean,
      default: false
    }
  },
  components: { AddToCalendar, EventTag, ImagePopup, SocialLink, Confirmation },
  data() {
    return {
      user: vweUser,
      showImage: false,
      showConfirmation: false,
      editBright: false
    };
  },
  computed: {
    eventImage() {
      if (this.event.optimizedImage) {
        return this.event.optimizedImage;
      }
      return this.event.eventImage;
    },
    eventName() {
      if (!this.event.videoUrl) {
        return "visit_event_site";
      }
      return "visit_event_video";
    },
    callableForEvent() {
      if (!this.event.videoUrl) {
        return "onViewEvent";
      }
      return "onVisitVideo";
    },
    mainLink() {
      if (!this.event.videoUrl) {
        return this.event.link;
      }
      return this.event.videoUrl;
    }
  },
  methods: {
    formatEventDate(d) {
      return (
        moment(d).format(this.date_format) +
        " " +
        moment.tz(moment.tz.guess()).format("zz")
      );
    },
    goEdit() {
      if (this.isPast()) {
        this.$router.push({
          name: "UserUpdateEvent",
          params: { id: this.event.id }
        });
        return;
      }
      this.$router.push({ name: "EditEvent", params: { id: this.event.id } });
    },
    maxStringLen() {
      if (this.no_truncate) {
        return 9999999;
      }
      return 750;
    },
    truncate(txt) {
      if (txt.length > this.maxStringLen()) {
        return txt.substring(0, this.maxStringLen());
      }
      return txt;
    },
    isPastToday() {
      let eventTime = moment(this.event.date);
      if (eventTime.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
        return false;
      }
      return moment().subtract(10, "minutes") > eventTime;
    },
    isStarted() {
      let eventTime = moment(this.event.date);
      if (eventTime.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
        return false;
      }
      let started =
        moment() > eventTime && moment().subtract(10, "minutes") < eventTime;
      return started;
    },
    isPast() {
      let eventTime = moment(this.event.date);
      return moment() > eventTime;
    },
    showAdmin() {
      if (this.user === null || this.user === undefined) {
        return false;
      }
      return this.user.isAdmin();
    },
    deleteMe() {
      this.showConfirmation = false;
      db.collection("events")
        .doc(this.event.id)
        .delete()
        .then(() => {
          this.$emit("deleted", { id: this.event.id });
        });
    },
    gotoEvent(eventType) {
      if (vweUser.isAdmin() && eventType !== "visit_event_video") {
        return;
      }
      // conditionally log the event.
      analytics.logEvent(eventType, {
        tags: this.event.categories,
        hour: moment(this.event.date).format("HH")
      });

      let viewDetailEvent = firebase
        .functions()
        .httpsCallable(this.callableForEvent);
      viewDetailEvent({
        id: vweUser.getInterestPersona(),
        eventId: this.event.id
      }).catch(err => {
        console.error("Error calling function ", err);
      });
    },
    addToCalendar() {
      if (vweUser.isAdmin()) {
        return;
      }
      // conditionally log the event.
      analytics.logEvent("add_to_calendar");
      // Make the call to the backend so we can register the interest.
      let viewDetailEvent = firebase
        .functions()
        .httpsCallable("onAddToCalendar");
      viewDetailEvent({
        id: vweUser.getInterestPersona(),
        eventId: this.event.id
      }).catch(err => {
        console.error("Error calling function ", err);
      });
    },
    shareEvent() {
      if (vweUser.isAdmin()) {
        return;
      }
      analytics.logEvent("share_event");
      let shareEvent = firebase.functions().httpsCallable("onShareEvent");
      shareEvent({
        id: vweUser.getInterestPersona(),
        eventId: this.event.id
      }).catch(err => {
        console.error("Error calling function ", err);
        errors.logException(err);
      });
    }
  }
};
</script>

<style scoped>
.event-time {
  font-size: 18px;
  font-weight: 500;
  color: #600000;
  padding-left: 20px;
  padding-bottom: 5px;
}

.event-cost {
  font-size: 18px;
  padding-left: 20px;
}

.event-free {
  color: green;
  font-weight: bold;
}

.image-spacer {
  width: 150px;
  height: 150px;
}
.event-image {
  margin: auto;
  max-height: 150px;
  max-width: 150px;
  height: auto;
  width: auto;
}

.event-title {
  font-size: 30px;
  font-weight: bold;
}

.content {
  margin-bottom: 10px;
}

a:link.event-link {
  color: black;
}

a:hover.event-link {
  color: #213a87;
}

.event-desc {
  white-space: pre-line;
}

.grayed {
  background-color: #e8e8e8;
}

.video-link {
  background-color: rgba(240, 237, 2, 0.3);
}

.past-text {
  font-weight: 700;
  font-size: 15px;
}

.larger-text {
  font-size: 1.2rem;
}

.tags.event-tags {
  margin-top: -7px;
}

.push-right {
  position: absolute;
  right: 0px;
  margin-right: 12px;
  margin-top: 12px;
}

.push-image {
  margin-top: 20px;
  margin-left: 70px;
}

.card-footer-item.is-cal-links {
  align-items: end;
}
.card-footer-item:not(:last-child) {
  border-right: 0px;
}

.event-tags.card-footer-item {
  justify-content: left;
  margin-bottom: 7px;
}
.card-footer-item.is-cal-links {
  justify-content: flex-end;
  margin-top: -6px;
}
.card-footer-item {
  padding: 1rem 0.75rem 0px 0px;
}
.card-content {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}
.sharing-links {
  display: flex;
}

a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 1.75em 1.25em 0;
  font-size: 0.7em;
}
a[class^="share-network-"] span {
  padding: 0 0.5em;
  flex: 1 1;
  font-weight: 500;
}
a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.55em;
  flex: 0 1 auto;
}

.has-text-primary-darker {
  color: #005c5b !important;
}

.edit-icon {
  margin-top: -1px;
}
</style>
