import Vue from "vue";

import firebase from "firebase/compat/app";
import auth from "firebase/compat/auth";
import db from "@/firebase/firestore";
import errors from "@/models/errors";
import md5 from "md5";
import { v4 as uuidv4 } from "uuid";

// most of this logic should be moved to Vuex when I have a chance.
const userModel = {
  user: localStorage.user ? JSON.parse(localStorage.user) : null,

  loggedIn() {
    return this.user !== null && this.user.id;
  },
  isAdmin() {
    if (this.user == null) {
      return false;
    }
    return this.user.is_admin;
  },
  setUser(user, done) {
    if (this.user != null && this.user.uid === user.uid) {
      return;
    }
    errors.setUser(user.uid, user.email);
    db.collection("users")
      .doc(user.uid)
      .get()
      .then(item => {
        if (item.exists) {
          this.user = item.data();
          this.user.id = item.id;
          localStorage.user = JSON.stringify(this.user);
        } else {
          this.user = {
            name: user.displayName,
            email: user.email,
            avatar_url: user.photoURL,
            twitter: "",
            instagram: "",
            is_admin: false
          };
          if (this.user.avatar_url == undefined || !this.user.avatar_url) {
            this.user.avatar_url =
              "https://www.gravatar.com/avatar/" + md5(this.user.email);
          }
          localStorage.user = JSON.stringify(this.user);
          // we have the user, now save it.
          db.collection("users")
            .doc(user.uid)
            .set(this.user)
            .then(ref => (this.user.id = user.uid))
            .catch(err => {
              errors.logException(err);
              this.user.id = user.uid; // we'll try to save the data again when we get the opportunity..
              console.error(err);
            });
        }
        if (done) {
          done(this.user);
        }
      })
      .catch(e => {
        console.error("Error building user: ", e);
      });
  },
  getUser() {
    return this.user;
  },
  getInterestPersona() {
    if (this.user) {
      return this.user.id;
    }
    if (localStorage["ga-on"]) {
      if (!localStorage["persona"]) {
        localStorage["persona"] = uuidv4();
      }
      return localStorage["persona"];
    }
    return null;
  },
  logOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("user");
        this.user = null;
      });
  },
  updateProfile(data, success, failure) {
    this.user.name = data.name;
    this.user.email = data.email;
    if (!data.twitter.startsWith("@") && data.twitter.length > 1) {
      data.twitter = "@" + data.twitter;
    }
    this.user.twitter = data.twitter;
    this.user.instagram = data.instagram;
    localStorage.user = JSON.stringify(this.user);

    db.collection("users")
      .doc(this.user.id)
      .set(this.user)
      .then(ref => success())
      .catch(err => failure());
  }
};

export default userModel;
